import listValidator from './listValidator';

const validClient = (client) => {
  const tempClient = {...client};
  delete tempClient.typeClient;
  const validate = listValidator.client;
  const temp = [];
  for (let key in tempClient) {
    if (!validate[key](tempClient[key])) {
      temp.push(key);
    }
  }
  return {status: temp.length > 0, error: temp};
};

const validLogin = user => {
  const temp = {...user};
  const validate = listValidator.login;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validRepair = repair => {
  const temp = {...repair};
  const validate = listValidator.repair;
  const result = Object.keys(temp).filter(key => !validate[key](temp[key]) ? key : null);
  return {status: result.length > 0, error: result};
};

const validProblem = problem => {
  const temp = {...problem};
  const validate = listValidator.problem;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};


const validId = id => {
  const temp = {...id};
  const validate = listValidator.id;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validRepairReason = reason => {
  const temp = {...reason};
  const validate = listValidator.repairReason;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validEquipment = equipment => {
  const temp = {...equipment};
  const validate = listValidator.equipment;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};


const validCategory = category => {
  const temp = {...category};
  const validate = listValidator.category;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validPriceType = price => {
  const temp = {...price};
  const validate = listValidator.priceType;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validRepairPrice = price => {
  const temp = {...price};
  const validate = listValidator.repairPrice;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};


const validNetClient = client => {
  const temp = {...client};
  const validate = listValidator.net_client;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validStateFirm = firm => {
  const temp = {...firm};
  const validate = listValidator.stateFirm;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validBranch = branch => {
  const temp = {...branch};
  const validate = listValidator.branch;
  const result = [];
  for (let key in temp) {
    if (['login', 'pass', 'sourceAddress', 'forReport', 'forRealisationReport'].includes(key)) continue;
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validFactory = factory => {
  const temp = {...factory};
  const validate = listValidator.factory;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validAdditionalServiceState = service => {
  const temp = {...service};
  const validate = listValidator.additionalServiceState;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key]) && key !== 'id') {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validAdditionalService = service => {
  const temp = {...service};
  const validate = listValidator.additionalService;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validSparePart = spare => {
  const temp = {...spare};
  const validate = listValidator.sparePart;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validWarrantyPeriod = spare => {
  const temp = {...spare};
  const validate = listValidator.warrantyPeriod;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};

const validConstantValue = spare => {
  const temp = {...spare};
  const validate = listValidator.constant_values;
  const result = [];
  for (let key in temp) {
    if (!validate[key](temp[key])) {
      result.push(key);
    }
  }
  return {status: result.length > 0, error: result};
};


export default {
  client: validClient,
  login: validLogin,
  validProblem: validProblem,
  validId: validId,
  repairReason: validRepairReason,
  equipment: validEquipment,
  category: validCategory,
  repair: validRepair,
  price: validPriceType,
  validRepairPrice: validRepairPrice,
  validNetClient: validNetClient,
  validStateFirm: validStateFirm,
  validBranch: validBranch,
  validFactory: validFactory,
  validAdditionalServiceState: validAdditionalServiceState,
  validAdditionalService: validAdditionalService,
  validSparePart: validSparePart,
  validWarrantyPeriod: validWarrantyPeriod,
  validConstantValue: validConstantValue,
};
