import React, {useState} from "react";
import {BrowserRouter, Route, Routes, Navigate, Link} from "react-router-dom";
import {Modal, Spin} from "antd";
import request from "./modules/api";
import BarcodeReader from "react-barcode-reader";
import {infoMessage, infoTypeError} from "./modules/helper";
import "./App.scss";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import config_i18n from "./config_i18n";
import {ConfigProvider} from 'antd';
import ruRU from 'antd/es/locale/ru_RU';
import en_US from 'antd/es/locale/en_US';
import zh_CN from 'antd/es/locale/zh_CN';
import 'dayjs/locale/ru';
import {useTranslation} from "react-i18next";
import NotAuthorized from "./views/Pages/NotAuthorized/NotAuthorized"

i18n.use(initReactI18next).init(config_i18n);

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

const App = () => {
  useTranslation()
  const [isLogin, toggleIsLogin] = useState(!!localStorage.getItem("token"));
  const [isOpenModal, toggleIsOpenModal] = useState(false);
  const [isLoading, toggleIsLoading] = useState(false);
  const [address, setAddress] = useState(null);
  const [repair, setRepair] = useState(null);
  const [racks, setRacks] = useState(null);
  const [document, setDocument] = useState({number: null});
  const [temp, setTemp] = useState('')
  const {language} = i18n;
  const onLogout = async () => {
    await request("auth/logout", {
      method: "POST",
    });
    localStorage.removeItem("token");
    localStorage.removeItem("isASC");
    toggleIsLogin();
  };

  const getValueCode = async (v) => {
    if (!isLoading) {
      if (v.code.includes('Digit') || v.code.includes('Key')) {
        setTemp(temp + v.code[v.code.length - 1])
      } else if (v.code === 'Slash') {
        setTemp(temp + '/')
      } else {
        toggleIsLoading(true);
        await setValueBarCode(temp)
        setTemp('')
      }
    }
  }

  const getDoc = async (id) => {
    const result = await request(`repair/scanBarCode/${id}`);
    if (result.status === 200 && result.data.data && result.data.data.racks) {
      setRepair(id);
      setRacks(result.data.data.racks);
      setDocument(result.data.data);
    }
  };

  const setValueBarCode = async (v) => {
    setRacks(null);
    const [id, type] = v.split("/");
    if (type) {
      if (type.toLocaleLowerCase() === "repair" || type.toLocaleLowerCase() === "model") {
        await getDoc(id);
      }
      if (address) {
        toggleIsOpenModal(false);
        await saveRacksRepair(address, id);
      } else {
        toggleIsOpenModal(true);
        toggleIsLoading(false);
      }
    } else {
      setAddress(id);
      if (repair) {
        toggleIsOpenModal(false);
        await saveRacksRepair(id, repair)
      } else {
        toggleIsOpenModal(true);
        toggleIsLoading(false);
      }
    }
  };


  const saveRacksRepair = async (address, repair) => {
    const response = await request(`racks/${repair}`, {
      method: "post",
      body: {
        address,
      },
    });
    setAddress(null);
    setRepair(null);
    toggleIsLoading(false);
    if (response.status === 200) {
      if (response.data.status) {
        infoMessage(
          "bottomRight",
          <span>
            {response.data.text}{" "}
            <a href={`/document_repair/${response.data.id}`} target={"__blank"}>
              {response.data.number}
            </a>
          </span>,
          60
        );
      } else
        infoTypeError(
          "bottomRight",
          <span>
            {response.data.text}
            {response.data.id && (
              <a
                href={`/document_repair/${response.data.id}`}
                target={"__blank"}
              >
                {response.data.number}
              </a>
            )}
          </span>,
          60
        );
    } else {
      infoTypeError(
        "bottomRight",
        "Ошибка интернет-соединения, попробуйте еще раз",
        60
      );
    }
  };

  const getValueRacks = (racks) => {
    if (racks) {
      return parseValueRacks(racks);
    } else return "";
  };

  const parseValueRacks = (racksValue) => {
    if (racksValue.includes("Z")) {
      return "У МСЦ";
    } else if (racksValue.includes("SSI") || racksValue.includes("start")) {
      return "У инженера";
    } else return racksValue;
  };

  return (
    <ConfigProvider locale={language === 'ru' ? ruRU : (language === 'en' ? en_US : zh_CN)}>
      <Spin spinning={isLoading}>
        <BrowserRouter>
          <React.Suspense fallback={loading()}>
            <Routes>
              <Route
                exact
                path="/login"
                name="Login Page"
                element={isLogin 
                  ? (<Navigate to={{pathname: "/"}}/>) 
                  : (<NotAuthorized toggleIsLogin={toggleIsLogin}/>)}
              />
              <Route
                path="*"
                name="Home"
                element={isLogin 
                  ? (<DefaultLayout logout={onLogout}/>) 
                  : (<Navigate to={{pathname: "/login"}}/>)
                }
              />
            </Routes>
          </React.Suspense>
          <BarcodeReader onKeyDetect={getValueCode}/>
          <Modal
            open={isOpenModal}
            closable={false}
            title={`Место хранения ${racks ? getValueRacks(racks) : ""}`}
            footer={false}
          >
            {address ? "Выберите модель" : "Выберите место хранения: "}
            {repair && (
              <Link
                target="_blank"
                onClick={(e) => e.target.blur()}
                to={`/document_repair/${repair}`}
                id="doc-link"
              >
                {document.number}
              </Link>
            )}
          </Modal>
        </BrowserRouter>
      </Spin>
    </ConfigProvider>
  );
};

export default App;
